import { Component, OnInit,ViewChild,AfterViewInit} from '@angular/core';
import { FormControl,FormGroup, FormBuilder, Validators,FormGroupDirective, NgForm } from '@angular/forms';
import { ActivatedRoute , Router } from '@angular/router';
import {ErrorStateMatcher} from '@angular/material/core';
import {MatDialog, MatDialogConfig} from "@angular/material/dialog";
import {MatTableDataSource} from '@angular/material/table'; 
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { ApiService } from '../services/api.service';
import { AppComponent } from '../app.component';
import { DialogComponent } from '../dialog.component';
import { MessageComponent } from '../message.component';
import { AssignmentComponent } from '../assignment.component';
import { environment } from '../../environments/environment';
import { SpinnerVisibilityService } from 'ng-http-loader';


@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})

export class LocationComponent implements OnInit,AfterViewInit {
	displayedColumns: string[] = ['action','activate','ReportingEntityLocationCode','CompanyName','DealershipType','EntityShort','DataCategory','SubEntityShort','AuditInfo'];
	assignmentDataSource = new MatTableDataSource();
	pageLength: number;
  	pageSize: number = 10;
  	dataLength: number
  	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(MatSort) sort: MatSort;	  
	errorMessage: any;
	successMessage: any;
    postLocation: FormGroup;   
	submitData: any = null;
	locationId;
	getCountryData: any = {};
	locationData: any = {};
	assignmentData: any = {};
	verifiedAddress: any = {};
	countryList: any[];
	stateList: any[];
	states:any[];
	primaryFacilityOwner: any[];
	primaryFacilityType: any[];
	userAcceptance: string = 'false';
    validateAddress:boolean = false;
	infoType: string;
	locationAuditRes:string;
	locationAuditInfo: any[];
	addLocationError:boolean = false;
	addLocationSuccess:boolean = false;
	addLocationErrorMsg: string;
	addLocationSuccessMsg:string;
	matcher = new MyErrorStateMatcher();
	locationStatusForm:FormGroup;
	editLocationExpand:boolean = true;
	@ViewChild('postLocationRef') postLocationForm;
	constructor(private ApiService: ApiService,private frmBuilder: FormBuilder,
		private dialog: MatDialog,private activeRoute: ActivatedRoute,
		private router: Router,
		private appComponent:AppComponent,
		private spinner: SpinnerVisibilityService) {
		this.activeRoute.params.subscribe(queryParams => {
			this.locationId = queryParams.locationId;
		});		
	 }
	ngOnInit() {		  
		this.postLocation = this.frmBuilder.group({
            country: ['', Validators.required],
			address_line1: ['', Validators.required],
            address_line2: [''],			
            city: ['', Validators.required],
            state: ['', Validators.required],
            primary_facility_name: ['', Validators.required],
            primary_facility_type: ['', Validators.required],
            primary_facility_owner: ['', Validators.required],
			status_notes: ['', Validators.required],
			postal:['', Validators.required],
			congressional_district:[''],
			county:[''],
			user_acceptance:[''],
			firstName:[this.appComponent.currentUser.firstName],
			lastName:[this.appComponent.currentUser.lastName],
			locationCode:['']
		});	
		
		this.getPrimaryFacility();
						
	}
	
	ngAfterViewInit() {		
		this.assignmentDataSource.sort = this.sort;	
	}
	get country() { return this.postLocation.get('country'); }
	get address_line1() { return this.postLocation.get('address_line1'); }
	get city() { return this.postLocation.get('city'); }
	get state() { return this.postLocation.get('state'); }
	get primary_facility_name() { return this.postLocation.get('primary_facility_name'); }
	get primary_facility_type() { return this.postLocation.get('primary_facility_type'); }
	get primary_facility_owner() { return this.postLocation.get('primary_facility_owner'); }
	get status_notes() { return this.postLocation.get('status_notes'); }


	getPrimaryFacility()
	{
		this.ApiService.getCountryState().subscribe( result => { 
			this.getCountryData = result; 
	   		this.countryList = result.country;
			this.primaryFacilityOwner = result.primaryFacilityOwner;
			this.primaryFacilityType = result.primaryFacilityType;
			this.getLocationData();
			},
			error => { 
			});
	}


	getLocationData() {
	if(this.locationId){				
		this.ApiService.getLocation(this.locationId).subscribe( result => {
			this.locationData = result.location[0];					
			var countryCodeInt = parseInt(this.locationData.Country);
			this.locationAuditRes = this.locationData.AuditInfo;
			this.locationAuditInfo = this.locationAuditRes.split(",");
			this.assignmentData = result.searchResult;
			this.assignmentDataSource.data = result.searchResult;		
			this.dataLength = result.searchResult.length;
			this.pageLength = Math.ceil(this.dataLength / this.pageSize);
			setTimeout(() => this.assignmentDataSource.paginator = this.paginator); 
			// this.stateList = this.ApiService.getObjects(this.states,countryCodeInt);
			var zipcode = this.locationData.Zipcode;
			if(this.locationData.Country != '1'){
				zipcode = this.locationData.PostalCode;
			}
			let body = {
				            "countryCode" : countryCodeInt
				        }
	        this.ApiService.getState(body).subscribe(result => {
	            this.states = result.state[0];
	            this.stateList = this.states;
	        },error => {
				console.log(error);
	        });
			this.postLocation.patchValue({
				address_line1: this.locationData.Address1,
				address_line2: this.locationData.Address2,
				city:this.locationData.City,
				state:this.locationData.State,
				country:countryCodeInt,
				primary_facility_name:this.locationData.Primary_Facility_Nm,
				postal:zipcode,
				congressional_district:this.locationData.Congressional_District,
				primary_facility_owner:parseInt(this.locationData.Primary_Facility_Owner),
				primary_facility_type:this.locationData.Primary_Facility_Type,
				county:this.locationData.US_County,
				firstName:[this.appComponent.currentUser.lastName],
				lastName:[this.appComponent.currentUser.lastName],
				locationCode:[this.locationId],
				status_notes:this.locationData.StatusNote
			  });
			},
			error => {   	
			});
	}
	}
	updateAssignments() {
		if(this.locationId) {				
			this.ApiService.getLocation(this.locationId).subscribe( result => {
				this.assignmentData = result.searchResult;
				this.assignmentDataSource.data = result.searchResult;			
				this.dataLength = result.searchResult.length;
				this.pageLength = Math.ceil(this.dataLength / this.pageSize);
				setTimeout(() => this.assignmentDataSource.paginator = this.paginator);
			},
			error => { 	
			});
		}
	}
	onSubmitAddLocation() {		
	if(!this.postLocation.valid)
		return;
	 this.submitData = this.postLocation.value;
    if(!this.validateAddress)
    {
    if(this.submitData.country==1){
		this.userAcceptance='false';
	}
	else{
		this.userAcceptance='true';
	}
    }
	 this.postLocation.patchValue({
		user_acceptance: this.userAcceptance
	  });
	  console.log(this.locationId)
	  if(this.locationId!==undefined && this.locationId!==null){
		this.ApiService.getLocation(this.locationId).subscribe( result => {
			this.locationData = result.location[0];					
		});
	  }
     this.ApiService.addLocation(this.postLocation.value)
	     .subscribe( result => {
			 if(result.status==200 && result.response.locationKey) {
				this.postLocationForm.resetForm();				
				this.router.navigate(['databook/location',result.response.locationKey,1009]);
			 }
			 else if(result.status==200 && result.error) {
				this.verifiedAddress = result.response.address.Records[0];
				this.openDialog('An error occurred while vefifying location address, Would you like to procced saving without address verification ?','error')	 
				return false;
			 } else if(result.status==200 && result.update) {
				this.verifiedAddress = result.response.address.Records[0];
				this.openDialog(result.response.address.Records[0],'update');
				return false;
			 } else if(result.successMsg) {
				this.addLocationSuccess = true;
				this.addLocationSuccessMsg = result.successMsg;
				this.toggleMessage('success');
			 }
			},
            error => {
				this.addLocationError = true;
				if(error.name =="HttpErrorResponse" || error.status =="500"){
					this.addLocationErrorMsg = environment.errorMsg500;
				}
				this.toggleMessage('success');
								
			});
	} 

    cancel(type) {
	   if(type=='add') {
	     this.postLocationForm.resetForm();
	   }   
	}
	manageLocation() {
		this.router.navigate(['databook/manage-location',1008]);
	}

	selectCountry(event) {     
		        let x = event.value;
		        console.log(event.value);
		        let body = {
		            "countryCode" : x
		        }
		        this.ApiService.getState(body).subscribe(result => {
		//           console.log(result);
		            this.states = result.state[0];
		            this.stateList = this.states;
		        },
		            error => {
		        });
		    }

	statusAssignment(locationXrefKey) {
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "CHANGE LOCATION ASSIGNMENT STATUS",
			title: 'CHANGE LOCATION ASSIGNMENT STATUS',
			page:'status-assignment',
			locationId:this.locationId,
			locationXrefKey:locationXrefKey
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AssignmentComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
					this.updateAssignments();
				}
				
		});	
	}
	async editAssignment(locationXrefKey){
		var assignmentObj = await this.getXrefKey(locationXrefKey);
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "CHANGE LOCATION ASSIGNMENT STATUS",
			title: 'CHANGE LOCATION ASSIGNMENT STATUS',
			page:'edit-assignment',
			locationId:this.locationId,
			locationXrefKey:locationXrefKey,
			assignmentObj:assignmentObj
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AssignmentComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
					this.updateAssignments();
				}
		});	
	}
	async getXrefKey(locationXrefKey) {
	return new Promise(async (resolve, reject) => {
		resolve(this.ApiService.getAssignmentUsingXrefKey(this.assignmentData,locationXrefKey));
	});
	}
	addAssignment() {
		this.editLocationExpand = true;
		const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "Add Assignment",
			title: 'Assignment dialog',
			page:'add-assignment',
			locationId:this.locationId
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AssignmentComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
					this.updateAssignments();
				}
		});		
	}
    openDialog(message,type) {
	this.infoType = type;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
        message: message,
		title: 'Address verfication failed',
		page:type
    };
	const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:MessageComponent},width:'650px'});
	dialogRef.afterClosed().subscribe(
		data =>{
		if(data) {	
            this.validateAddress = true;
			this.userAcceptance = 'true';
			if(this.infoType == "update"){
				if(this.verifiedAddress.CountryName){
					this.postLocation.patchValue({
						address_line1: this.verifiedAddress.AddressLine1,
						address_line2:this.verifiedAddress.AddressLine2,
						city:this.verifiedAddress.Locality,
						state:this.verifiedAddress.AdministrativeArea,
						postal:this.verifiedAddress.PostalCode,
						county:this.verifiedAddress.SubAdministrativeArea,
						congressional_district:this.verifiedAddress.AdministrativeArea+"UH0"
					  });
				} else {
					this.postLocation.patchValue({
						address_line1: this.verifiedAddress.AddressLine1,
						address_line2:this.verifiedAddress.AddressLine2,
						city:this.verifiedAddress.City,
						state:this.verifiedAddress.State,
						postal:this.verifiedAddress.PostalCode,
						county:this.verifiedAddress.CountyName,
						congressional_district:this.verifiedAddress.State+"UH0"+this.verifiedAddress.CongressionalDistrict
					  });
				}
				
			}
			this.onSubmitAddLocation();
		} else{
			 this.validateAddress = true;
			if(this.infoType == "update"){
              	this.userAcceptance = 'true'; 
              	this.validateAddress = true;
			    this.onSubmitAddLocation();
			 }
		}
	});
	}
	locationStatus(status,locationCode){
		this.locationStatusForm = this.frmBuilder.group({ 
			DatabookLocationCode:locationCode,
			name:this.appComponent.currentUser.firstName+' '+this.appComponent.currentUser.lastName,
			email:this.appComponent.currentUser.emailAddress
		});
		if(status == 'A'){
			this.activateAllLocation();
			this.editLocationExpand = true;
		} else{
			this.deactivateAllLocation();
			this.editLocationExpand = true;
		}
	}
	activateAllLocation() {
		this.ApiService.activateAllLocation(this.locationStatusForm.value)
	     .subscribe( result => {
			this.addLocationSuccess = true;
			this.addLocationSuccessMsg = result.Success;
			this.toggleMessage('success');	 
			this.getLocationData();			
			},
            error => {
				this.addLocationError = true;
				if(error.name =="HttpErrorResponse" || error.status =="500"){
					this.addLocationErrorMsg = environment.errorMsg500;
				}
				this.toggleMessage('error');								
			});
	}
	deactivateAllLocation() {
		this.ApiService.deactivateAllLocation(this.locationStatusForm.value)
	     .subscribe( result => {
			this.addLocationSuccess = true;
			this.addLocationSuccessMsg = result.Success;
			this.toggleMessage('success');			 
			 this.getLocationData();			 
			},
            error => {
				this.addLocationError = true;
				if(error.name =="HttpErrorResponse" || error.status =="500"){
					this.addLocationErrorMsg = environment.errorMsg500;
				}
				this.toggleMessage('error');								
			});
	}
	
	toggleMessage(type){
		setTimeout(() => {
		 if(type == 'success'){
		  this.addLocationSuccess = false;
		 } else {
		  this.addLocationError = false;
		 }      
	  }, 8000);
	  }
}
/** Error when invalid control is dirty, touched, or submitted.*/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted; 
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
