import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, pipe } from 'rxjs';
import html2canvas from 'html2canvas';
import * as jspdf from 'jspdf'
import * as XLSX from 'xlsx';
import { saveAs } from "file-saver";
const xlsOptions = {}; 

@Injectable()
export class DownloadService {
  constructor(private http:HttpClient) {}
  downloadPDF(pdfData,pdfFilename){    
    html2canvas(pdfData).then(canvas => {  
      // Few necessary setting options  
      var imgWidth = 208;   
      var pageHeight = 295;    
      var imgHeight = canvas.height * imgWidth / canvas.width;  
      var heightLeft = imgHeight;  
  
      const contentDataURL = canvas.toDataURL('image/png')  
      let pdf = new jspdf('p', 'mm', 'a4'); // A4 size page of PDF  
      var position = 0;  
      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)  
      pdf.save(pdfFilename); // Generated PDF   
    });  
  }
  getXLS(config: string,element: any,fileName): Observable<string | null> {
    return Observable.create((observer) => {
	var excelOptions = {};      
      const ws3 = XLSX.utils.json_to_sheet(element, excelOptions);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws3,'Sheet1');
      const out = XLSX.write(wb, { type: 'base64' });
      const xlsContent = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + out;
      if (true) {
        this.download(fileName, xlsContent);
        observer.next();
      } else {
        observer.next(xlsContent);
      }
      observer.complete();
    });
  }
  download(fileName: string, dataURL: string): void {
    this.contentToBlob(dataURL).subscribe(blob => {
      const element = document.createElement('a');
      const url = window.URL.createObjectURL(blob);
      element.setAttribute('download', fileName);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.href = url;
      element.click();
      document.body.removeChild(element);
    });
  }
  contentToBlob(content: string): Observable<Blob> {
    return Observable.create((observer) => {
      const arr = content.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1])
      var n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      observer.next(new Blob([u8arr], {type: mime}));
      observer.complete();
    });
  }
  print(printContent){
	let popupWin;
	popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(printContent);
    popupWin.document.close();
  }

}