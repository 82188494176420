import {Component, Inject, OnInit,ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import { ApiService } from './services/api.service';
import { environment } from '../environments/environment';
import {interval} from "rxjs";

@Component({
    selector: 'app-message',
    templateUrl: './message.component.html',
    styleUrls:['./message.component.css']
})
export class MessageComponent implements OnInit {
content: any;
progress;
canBeClosed = true; 
primaryButtonText = 'Upload';
showCancelButton = true; 
uploading = false;
uploadSuccessful = false;
uploadSuccess: boolean = false;
uploadError: boolean ; false;
uploadSuccessMsg: string;
uploadErrorMsg: string;
awsToken:any;
@ViewChild('file') file;
  public files: Set<File> = new Set();
    constructor(private dialogRef: MatDialogRef<MessageComponent>,
        @Inject(MAT_DIALOG_DATA) public data : any,
        private apiService: ApiService) {
              this.dialogRef.disableClose = true;  
              this.content = data.dialogConfig.data;             
            }
    ngOnInit() {
      if(this.content.page == 'upload'){
        this.getS3Token();        
        const subscription = interval(1800000)
        .subscribe(()=>{ 
         this.getS3Token();
        });
      }
     }
     getS3Token() {
      this.apiService.getSTSToken()
      .subscribe(data => {
        this.awsToken = data;
      },
      error=>{
      });          
      }
    saveDialog(response): void {
        this.dialogRef.close(response);
    }
    closeDialog(response): void {    
        this.dialogRef.close(response);
    }
    proceedRefresh(response){
      this.dialogRef.close(response);
    }
    addFiles() {
        this.file.nativeElement.click();
      }
    onFilesAdded() {  
        const files: { [key: string]: File } = this.file.nativeElement.files;              
        for (let key in files) {
          if (!isNaN(parseInt(key))) {
            var fileSize = files[key].size;  
            var fileName = files[key].name.split('.');
            // var fileFormat = fileName[fileName.length - 1];
            var fileFormat = fileName.pop();
             if(fileSize > 10486000) {
               this.uploadError = true;
               this.uploadErrorMsg = environment.maxFileuploadErrorMsg;
               return false;
             } else if ( fileFormat !== 'txt' ){
              this.uploadError = true;
              this.uploadErrorMsg = 'Please upload only .txt file';
             } else {
              this.uploadError = false;
              this.files.add(files[key]);
             }
          }
        }
      }
    async closeDialogUpload() {
        if (this.uploadSuccessful) {
          return this.dialogRef.close();
        }
        if(!this.files.size) {
          return false;  
        }    
        this.uploading = true;   
        // start the upload and save the progress map
        if(this.awsToken == null || this.awsToken == undefined) {
          await this.getS3Token();      
        }
        this.progress = await this.apiService.upload(this.files,this.content.dataCategoryId,this.content.entityId,this.awsToken); 
        if(this.progress.ETag){     
        this.uploadSuccess = true;
        this.uploadSuccessMsg = "Successfully uploaded file, Please close this popup to continue your process.";
        } else {
          this.uploadError = true;
          this.uploadErrorMsg = "Oops! There was an error uploading your file, Please close popup window and try again.";
        }
        this.primaryButtonText = 'Finish';
        this.uploadSuccessful = true;   
        // The dialog should not be closed while uploading  
        this.canBeClosed = true;
        this.dialogRef.disableClose = true;            
        // Hide the cancel-button    
        this.showCancelButton = false;      
      }
}