import { Component, OnInit } from '@angular/core';
import * as pbi from 'powerbi-client';
import * as models from 'powerbi-models';
import { ApiService } from '../services/api.service';

@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.css']
})

export class DistrictComponent implements OnInit{
	permissions = models.Permissions.All;	
	layOut:any = models.LayoutType.Custom;
	isIEOrEdge: boolean = false;
	constructor(private ApiService: ApiService) { }
	ngOnInit() {	
		 this.getReports();
		 var isMobile = false;
		 isMobile = this.ApiService.isMobile();	
		if(isMobile){
			this.layOut = models.LayoutType.MobilePortrait
		}
		this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
	}   
	/** -----------------------Power BI Report embed code starts ----------------------------**/
	getReports() {
	this.ApiService.getReportsData(2).subscribe(
	      data => { 
			// Report's Secured Token     
			let accessToken = data.token;			
			// Embed URL 
			let embedUrl = data.embedUrl;			
			// Report ID
			let embedReportId = data.reportId;
			// Configuration used to describe the what and how to embed.	
			let setting:any = {
			filterPaneEnabled: true,
            navContentPaneEnabled: true
			/*pageView: 'fitToWidth',
			layoutType: this.layOut,
			customLayout: {			
			displayOption: models.DisplayOption.ActualSize
		}*/
		
	};	
    let config = {
        type: 'report',		
        tokenType:models.TokenType.Embed,
        accessToken: accessToken,
        embedUrl: embedUrl,
        id: embedReportId,
		permissions:this.permissions,
		settings:setting       
    };
	
    // Grab the reference to the div HTML element that will host the report.
    let reportContainer = <HTMLElement>document.getElementById('reportContainer');

    // Embed the report and display it within the div container.
    let powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
    let report = powerbi.embed(reportContainer, config);
	//report.fullscreen();
    // Report.off removes a given event handler if it exists.
    report.off("loaded");

    // Report.on will add an event handler which prints to Log window.
    report.on("loaded", function() {
    });
	      }
	  );
	}
	/** -----------------------Power BI Report embed code ends----------------------------**/
}