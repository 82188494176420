import {Injectable} from '@angular/core';
import { HttpClient, HttpHeaders,HttpRequest, HttpEventType, HttpResponse, } from '@angular/common/http';
import { Observable, Subject, pipe } from 'rxjs';
import { environment } from '../../environments/environment';
import * as AWS from 'aws-sdk';
import * as S3 from 'aws-sdk/clients/s3';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class ApiService {
apiCommonPath = environment.apiCommonPath;
accessTokenBaseURL = environment.accessTokenBaseURL;
refreshReportBaseURL = environment.refreshReport;
locationBaseURL = environment.locationBaseURL;
userBaseURL = environment.userDetailsBaseURL;
uploadDataColumnsBaseURL = environment.uploadDataColumnsBaseURL;
contactBaseURL = environment.contactBaseURL;
adminListBaseURL = environment.adminListBaseURL;
companyListBaseURL = environment.companyListBaseURL;
dataProviderBaseURL = environment.dataProviderBaseURL;
repotingPeriodBaseURL = environment.repotingPeriodBaseURL;
manualEntryBaseURL = environment.manualEntryBaseURL;
exchangeRatesBaseURL = environment.exchangeRatesBaseURL;
manageContentBaseURL = environment.manageContentBaseURL;
manageEntityBaseURL = environment.manageEntityBaseURL;
reportPageBaseURL = environment.reportsPageBaseURL;
economicImpactDataBaseURL = environment.economicImpactDataBaseURL;
manageSubEntityBaseURL = environment.manageSubEntityBaseURL;
manageCategoryBaseURL = environment.manageCategoryBaseURL;
saveUserDetailURL = environment.saveUserDetailURL

constructor(private http:HttpClient,private toastr: ToastrService) {}
acccessTokenUrl = this.accessTokenBaseURL+'/'+this.apiCommonPath+'/'+environment.accessTokenName;
refreshReportURL = this.refreshReportBaseURL+'/'+this.apiCommonPath+'/'+environment.getRefreshedReport;
searchLocationURL = this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.searchLocationPath;
saveLocationUrl = this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.saveLocationPath;
getAssignmentScreenURL = this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.addAssignmentResponse;
getSubEntityURL = this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.getSEntity;
addAssignmentUrl = this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.addAssignment;
updateAssignmentURL = this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.updateAssignment;
getLocationURL = this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.getLocation;
getCountryStateURL = this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.getLocationFormData;
getCountryURL = this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.getCountryStateURL;
getDataCategoryURL = this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.getDataCategory;
assignmentStatusURL = this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.assignmentStatus;
activateAllLocationURL = this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.activateAllLocation;
deactivateAllLocationURL =  this.locationBaseURL+'/'+this.apiCommonPath+'/'+environment.deactivateAllLocation;

getUserDetailsURL = this.userBaseURL+'/'+this.apiCommonPath+'/'+environment.getLoginResponse;
getReferenceURL =  this.uploadDataColumnsBaseURL+'/'+this.apiCommonPath+'/'+environment.getUploadDatacolumns;
getCsvDetailsURL = this.uploadDataColumnsBaseURL+'/'+this.apiCommonPath+'/'+environment.getCsvDetails;
glossaryDetailColunmURL = this.uploadDataColumnsBaseURL+'/'+this.apiCommonPath+'/'+environment.getGlossaryDetailsColumns;
getFileStatusURL = this.uploadDataColumnsBaseURL+'/'+this.apiCommonPath+'/'+environment.getFileStatus;
getFileToUploadDetailsURL = this.uploadDataColumnsBaseURL+'/'+this.apiCommonPath+'/'+environment.getFileToUploadDetails;
contactURL =  this.contactBaseURL+'/'+this.apiCommonPath+'/'+environment.getContact;
updateContactURL = this.contactBaseURL+'/'+this.apiCommonPath+'/'+environment.updateContact;
addContactURL = this.contactBaseURL+'/'+this.apiCommonPath+'/'+environment.addContact;
removeContactURL = this.contactBaseURL+'/'+this.apiCommonPath+'/'+environment.removeContact;
getAdminListURL = this.adminListBaseURL+'/'+this.apiCommonPath+'/'+environment.getAdminsList;
deleteAdminURL = this.adminListBaseURL+'/'+this.apiCommonPath+'/'+environment.deleteAdmin;
addAdminURL = this.adminListBaseURL+'/'+this.apiCommonPath+'/'+environment.addAdmin;
getAllRolesURL = this.adminListBaseURL+'/'+this.apiCommonPath+'/'+environment.getAllRoles;
getCompanyListURL = this.companyListBaseURL+'/'+this.apiCommonPath+'/'+environment.getCompanyList;
deleteCompanyURL = this.companyListBaseURL+'/'+this.apiCommonPath+'/'+environment.deleteCompany;
addCompanyURL = this.companyListBaseURL+'/'+this.apiCommonPath+'/'+environment.addCompany;
getEntityURL = this.companyListBaseURL+'/'+this.apiCommonPath+'/'+environment.getEntity;
getDataProviderURL = this.dataProviderBaseURL+'/'+this.apiCommonPath+'/'+environment.getDataProvider;
getDataProviderURL1 = this.dataProviderBaseURL+'/'+this.apiCommonPath+'/'+environment.getDataProvider1;
addDataProviderURL = this.dataProviderBaseURL+'/'+this.apiCommonPath+'/'+environment.addDataProvider;
getExistingDataCategoryURL = this.dataProviderBaseURL+'/'+this.apiCommonPath+'/'+environment.getExistingDataCategory;
getAllAdminDetailsURL = this.dataProviderBaseURL+'/'+this.apiCommonPath+'/'+environment.getAllAdminsDetails;
getEntityDatacategoryDetailsURL = this.dataProviderBaseURL+'/'+this.apiCommonPath+'/'+environment.getEntityDatacategoryDetails;
updateDataproviderProfileURL =this.dataProviderBaseURL+'/'+this.apiCommonPath+'/'+environment.updateDataproviderProfile;
deleteDataproviderURL = this.dataProviderBaseURL+'/'+this.apiCommonPath+'/'+environment.deleteDataprovider;
deleteDataproviderProfileURL = this.dataProviderBaseURL+'/'+this.apiCommonPath+'/'+environment.deleteDataproviderProfile;
getAllReportingperiodsURL = this.repotingPeriodBaseURL+'/'+this.apiCommonPath+'/'+environment.getAllReportingperiods;
reopenReportingperiodURL = this.repotingPeriodBaseURL+'/'+this.apiCommonPath+'/'+environment.reopenReportingperiod;
closeReportingperiodURL = this.repotingPeriodBaseURL+'/'+this.apiCommonPath+'/'+environment.closeReportingperiod;
getDatacategoriesURL = this.repotingPeriodBaseURL+'/'+this.apiCommonPath+'/'+environment.getDatacategories;
openNewReportingperiodURL = this.repotingPeriodBaseURL+'/'+this.apiCommonPath+'/'+environment.openNewReportingperiod;
updateExchangeRatesURL = this.exchangeRatesBaseURL+'/'+this.apiCommonPath+'/'+environment.updateExchangeRates;
getManualEntryURL = this.manualEntryBaseURL+'/'+this.apiCommonPath;
saveApproverManualEntryURL = this.manualEntryBaseURL+'/'+this.apiCommonPath+'/'+environment.saveApproverManualEntry;
saveManualEntryDetailsURL = this.manualEntryBaseURL+'/'+this.apiCommonPath+'/'+environment.saveManualEntryDetails;
getCurrencyCodeURL = this.manualEntryBaseURL+'/'+this.apiCommonPath+'/'+environment.getCurrencyCode;

getSectionURL = this.manageContentBaseURL+'/'+this.apiCommonPath+'/'+environment.getSectionList;
getAllContentURL = this.manageContentBaseURL+'/'+this.apiCommonPath+'/'+environment.getAllContentList;
getContentURL = this.manageContentBaseURL+'/'+this.apiCommonPath+'/'+environment.getContentList;
createSectionURL = this.manageContentBaseURL+'/'+this.apiCommonPath+'/'+environment.createSection;
createContentURL = this.manageContentBaseURL+'/'+this.apiCommonPath+'/'+environment.createContent;
updateSectionURL =  this.manageContentBaseURL+'/'+this.apiCommonPath+'/'+environment.updateSection;
updateContentURL = this.manageContentBaseURL+'/'+this.apiCommonPath+'/'+environment.updateContent;
deleteSectionURL = this.manageContentBaseURL+'/'+this.apiCommonPath+'/'+environment.deleteSection;
deleteContentURL = this.manageContentBaseURL+'/'+this.apiCommonPath+'/'+environment.deleteContent;

getAllEntityURL = this.manageEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.getAllEntity;
getAllCountryURL = this.manageEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.getAllCountry;
addEntityURL = this.manageEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.addEntity;
editEntityURL = this.manageEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.editEntity;
getDataproviderEntityURL = this.manageEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.getDataproviderEntity;
getLocationAssignmentEntityURL = this.manageEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.getLocationAssignmentEntity;
statusChangeEntityURL = this.manageEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.statusChangeEntity;


getAllSubEntityURL = this.manageSubEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.getAllSubEntity;
getEntityListURL = this.manageSubEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.getEntityList;
addSubEntityURL = this.manageSubEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.addSubEntity;
editSubEntityURL = this.manageSubEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.editSubEntity;
addSubEntityAssignmentURL = this.manageSubEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.addSubEntityAssignment;
editSubEntityAssignmentURL = this.manageSubEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.editSubEntityAssignment;
getAllSubEntityAssignmentURL = this.manageSubEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.getAllSubEntityAssignment;
getLocationAssignmentSubentityURL = this.manageSubEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.getLocationAssignmentSubentity;
statusChangeSubentityURL = this.manageSubEntityBaseURL+'/'+this.apiCommonPath+'/'+environment.statusChangeSubentity;

getAllCategoryURL = this.manageCategoryBaseURL+'/'+this.apiCommonPath+'/'+environment.getAllCategory;
getDataCategoryDropdownURL = this.manageCategoryBaseURL+'/'+this.apiCommonPath+'/'+environment.getDataCategoryDropdown;
addDataCategoryURL = this.manageCategoryBaseURL+'/'+this.apiCommonPath+'/'+environment.addDataCategory;
editDataCategoryURL = this.manageCategoryBaseURL+'/'+this.apiCommonPath+'/'+environment.editDataCategory;
getDataproviderCategoryURL = this.manageCategoryBaseURL+'/'+this.apiCommonPath+'/'+environment.getDataproviderCategory;
getLocationAssignmentCategoryURL = this.manageCategoryBaseURL+'/'+this.apiCommonPath+'/'+environment.getLocationAssignmentCategory;
statusChangeDataCategoryURL = this.manageCategoryBaseURL+'/'+this.apiCommonPath+'/'+environment.statusChangeDataCategory;

getReportsLinkURL = this.reportPageBaseURL+'/'+this.apiCommonPath+'/'+environment.getReportsLinks;

saveAuditScreenLogDataURL = this.saveUserDetailURL+'/'+this.apiCommonPath+'/'+environment.saveAuditScreenLogDataURL;

getAllEconomicImpactDataURL = this.economicImpactDataBaseURL+'/'+this.apiCommonPath+'/'+environment.getAllEconomicImpactData;
createEconomicImpactDataURL = this.economicImpactDataBaseURL+'/'+this.apiCommonPath+'/'+environment.createEconomicImpactData;
updateEconomicImpactDataURL = this.economicImpactDataBaseURL+'/'+this.apiCommonPath+'/'+environment.updateEconomicImpactData;

getSTSTokenBaseURL = environment.getSTSTokenBaseURL;
getSTSTokenPath = environment.getSTSTokenPath;
assumeRoleURL = this.getSTSTokenBaseURL +'/'+this.getSTSTokenPath+'/'+environment.AssumeRole;

getAuthTokenBaseURL = environment.authTokenBaseURL;
getAuthTokenPath = environment.authTokenPath;
getAuthTokenURL = this.getAuthTokenBaseURL +'/'+this.getAuthTokenPath+'/'+environment.generateToken;

getS3FileBaseURL = environment.getS3FileBaseURL;
getS3filePath = environment.getS3FilePath;
getS3Files = environment.getS3FileBaseURL+'/'+environment.getS3FilePath+'/'+environment.getS3FileURL;

	getObjects(obj,value) {
		var temp;
		obj.forEach(function(element) {
    	  if(element[value]){
    	    temp = element[value];               
    	  }
		});
		return temp;
	}
	getAssignmentUsingXrefKey(obj,xrefKey) {
		var temp;
		obj.forEach(function(element) {
		  
		  if(element.LocationXrefKey == xrefKey)
		  {
			temp = element;
		  }			
		});
		return temp;
	}
	getAdminInfo(obj,selectedId) {
		console.log("object",obj);
		console.log("selectedID",selectedId);
		var temp;
		obj.forEach(function(element) {
		  if(element.id == selectedId) {
			temp = element;            
		  }
		});
		return temp;
	}
	isMobile() {		
		if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
			|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
			return true;
		}
	}
	getYears(currentYear,count) {
		var temp = [];
		for(var i=(currentYear-count);i<=(currentYear+count);i++){
			temp.push(i);
		}
		return temp;
	  }
	getContacts(): Observable<any> {
        return this.http.get<any>(this.contactURL);
	}
	updateContact(data:any):Observable<any> {		  
		return this.http.post<any>(this.updateContactURL,data);
	}
	addContact(data:any):Observable<any> {		  
		return this.http.post<any>(this.addContactURL,data);
	}
	removeContact(id:number):Observable<any> {		  
		return this.http.get<any>(this.removeContactURL+'/'+id);
	}
	getReportsData(id:number): Observable<any> {
		return this.http.get<any>(this.acccessTokenUrl+'/'+id);
	}
	refreshAllReports(): Observable<any> {
		return this.http.get<any>(this.refreshReportURL);
	}
	searchLocation(data:any):Observable<any> {		  
		return this.http.post<any>(this.searchLocationURL,data);
	}
	addLocation(data: any): Observable<any> {
		return this.http.post(this.saveLocationUrl, data);
	}
	getCountryState(): Observable<any> {   
		return this.http.get<any>(this.getCountryStateURL);
	}
	getState(data: any): Observable<any> {   
		return this.http.post<any>(this.getCountryURL, data);
	}
	getDataCategory(data : any): Observable<any>{
		return this.http.post<any>(this.getDataCategoryURL, data);
	}
	getSubEntity(data : any): Observable<any>{
		return this.http.post<any>(this.getSubEntityURL, data);
	}
	getLocation(locationCode:number): Observable<any> {
		return this.http.get<any>(this.getLocationURL+'/'+locationCode);
	}
	getUserDetails(obj: any,json: boolean): Observable<any> {
		var access_token=obj;		 
		var data = {'accessToken':access_token};
		return this.http.post<any>(this.getUserDetailsURL,data);
	}
	getAssignmentScreenData(): Observable<any> {
		return this.http.get<any>(this.getAssignmentScreenURL);
	}
	addAssignment(data: any): Observable<any> {
		return this.http.post(this.addAssignmentUrl, data);
	}
	updateAssignment(data: any): Observable<any> {
		return this.http.post(this.updateAssignmentURL, data);
	}
	activateAllLocation(data: any): Observable<any> {
		return this.http.post(this.activateAllLocationURL, data);
	}
	deactivateAllLocation(data: any): Observable<any> {
		return this.http.post(this.deactivateAllLocationURL, data);
	}
	assignmentStatus(data: any): Observable<any> {
		return this.http.post(this.assignmentStatusURL, data);
	}
	getfileStatus(dataCategoryId:number,entityId:number): Observable<any> {
		return this.http.get<any>(this.getFileStatusURL+'/'+dataCategoryId+'/'+entityId);
	}
	getDataprovidersDatacategory(emailId:string): Observable<any> {
		return this.http.get<any>(this.getFileToUploadDetailsURL+'/'+emailId);
	}
	getColumns(dataCategoryId:number,entityId:number): Observable<any> {
		return this.http.get<any>(this.getReferenceURL+'/'+dataCategoryId+'/'+entityId);
	}
	getCsvDetails(data: any): Observable<any> {
		return this.http.post(this.getCsvDetailsURL, data);
	}
	getGlossaryDetailsColumns(dataCategoryId:number): Observable<any> {
		return this.http.get<any>(this.glossaryDetailColunmURL+'/'+dataCategoryId);
	}	
	getAdminsList(): Observable<any> {
		return this.http.get<any>(this.getAdminListURL);
	}
	getAllRoles(): Observable<any> {
		return this.http.get<any>(this.getAllRolesURL);
	}
	deleteAdmin(adminId:number): Observable<any> {
		return this.http.get<any>(this.deleteAdminURL+'/'+adminId);
	}
	addAdmin(data: any): Observable<any> {
		return this.http.post(this.addAdminURL, data);
	}
	getCompanyList(): Observable<any> {
		return this.http.get<any>(this.getCompanyListURL);
	}
	deleteCompany(companyId:number): Observable<any> {
		return this.http.get<any>(this.deleteCompanyURL+'/'+companyId);
	}
	addCompany(data: any): Observable<any> {
		return this.http.post(this.addCompanyURL, data);
	}
	getEntity(): Observable<any> {
		return this.http.get<any>(this.getEntityURL);
	}	
	getDataProviders(): Observable<any> {
		return this.http.get<any>(this.getDataProviderURL);
	}
	getDataProviders1(data:any): Observable<any> {
		return this.http.post<any>(this.getDataProviderURL1, data);
	}
	addDataProvider(data: any): Observable<any> {
		return this.http.post(this.addDataProviderURL, data);
	}
	getExistingDataCategory(providerId:number): Observable<any> {
		return this.http.get<any>(this.getExistingDataCategoryURL+'/'+providerId);
	}
	getAllAdminsDetails(): Observable<any> {
		return this.http.get<any>(this.getAllAdminDetailsURL);
	}
	getEntityDataCategory(): Observable<any> {
		return this.http.get<any>(this.getEntityDatacategoryDetailsURL);
	}
	deleteDataprovider(providerId:number){
		return this.http.get<any>(this.deleteDataproviderURL+'/'+providerId);
	}
	deleteDataproviderCategory(categoryId:number){
		return this.http.get<any>(this.deleteDataproviderProfileURL+'/'+categoryId);
	}
	updateDataproviderCategory(data: any): Observable<any> {
		return this.http.post(this.updateDataproviderProfileURL, data);
	}
	getAllReportingperiods(): Observable<any> {
		return this.http.get<any>(this.getAllReportingperiodsURL);
	}
	reopenReportingperiod(data:any): Observable<any> {
		return this.http.post(this.reopenReportingperiodURL,data);
	}
	closeReportingperiod(data:any): Observable<any> {
		return this.http.post(this.closeReportingperiodURL,data);
	}
	getDatacategories(){
		return this.http.get<any>(this.getDatacategoriesURL);
	}
	openNewReportingperiod(data: any): Observable<any> {
		return this.http.post(this.openNewReportingperiodURL, data);
	}
	updateExchangeRates(reportingPeriodId:number) {
		return this.http.get<any>(this.updateExchangeRatesURL+'/'+reportingPeriodId);
	}
	getManualEntryData(entityId:number,dataCategoryId:number,categoryURL: string) {
		return this.http.get<any>(this.getManualEntryURL+'/'+categoryURL+'/'+entityId+'/'+dataCategoryId);
	}
	getCurrencyCode(): Observable<any> {
		return this.http.get<any>(this.getCurrencyCodeURL);
	}
	saveApproverManualEntry(data: any): Observable<any> {
		return this.http.post(this.saveApproverManualEntryURL, data);
	}
	saveManualEntryDetails(data: any): Observable<any> {
		return this.http.post(this.saveManualEntryDetailsURL, data);
	}
	getSectionList(): Observable<any> {
		return this.http.get<any>(this.getSectionURL);
	}
	createSection(data: any): Observable<any> {
		return this.http.post(this.createSectionURL, data);
	}
	updateSection(data: any): Observable<any> {
		return this.http.post(this.updateSectionURL, data);
	}
	deleteSection(sectionId:number){
		return this.http.get<any>(this.deleteSectionURL+'/'+sectionId);
	}
	getContentList(sectionId:number): Observable<any> {
		return this.http.get<any>(this.getContentURL+'/'+sectionId);
	}
	getAllContentList(): Observable<any> {
		return this.http.get<any>(this.getAllContentURL);
	}
	createContent(data: any): Observable<any> {
		return this.http.post(this.createContentURL, data);
	}
	updateContent(data: any): Observable<any> {
		return this.http.post(this.updateContentURL, data);
	}
	deleteContent(ContentId:number): Observable<any>{
		return this.http.get<any>(this.deleteContentURL+'/'+ContentId);
	}
	getAllEntity(): Observable<any> {
		return this.http.get<any>(this.getAllEntityURL);
	}
	getAllCountry(): Observable<any> {
		return this.http.get<any>(this.getAllCountryURL);
	}
	addEntity(data: any): Observable<any> {
		return this.http.post(this.addEntityURL, data);
	}
	editEntity(data: any): Observable<any> {
		return this.http.post(this.editEntityURL, data);
	}
	getAllSubEntity(): Observable<any> {
		return this.http.get<any>(this.getAllSubEntityURL);
	}
	getEntityList(): Observable<any> {
		return this.http.get<any>(this.getEntityListURL);
	}
	getDataProviderStatusEntity(status:string,entityCode:number): Observable<any> {
		return this.http.get<any>(this.getDataproviderEntityURL+'/'+status+'/'+entityCode);
	}
	getLocationAssignmentStatusEntity(status:string,entityCode:number): Observable<any> {
		return this.http.get<any>(this.getLocationAssignmentEntityURL+'/'+status+'/'+entityCode);
	}
	statusChangeEntity(data:any): Observable<any> {
		return this.http.post<any>(this.statusChangeEntityURL,data);
	}
	addSubEntity(data: any): Observable<any> {
		return this.http.post(this.addSubEntityURL, data);
	}
	editSubEntity(data: any): Observable<any> {
		return this.http.post(this.editSubEntityURL, data);
	}
	addSubEntityAssignment(data: any): Observable<any> {
		return this.http.post(this.addSubEntityAssignmentURL, data);
	}
	editSubEntityAssignment(data: any): Observable<any> {
		return this.http.post(this.editSubEntityAssignmentURL, data);
	}
	getAllSubEntityAssignment(subEntityId:number): Observable<any>{
		return this.http.get<any>(this.getAllSubEntityAssignmentURL+'/'+subEntityId);
	}
	getLocationAssignmentStatusSubentity(status:string,subEntityCode:number): Observable<any> {
		return this.http.get<any>(this.getLocationAssignmentSubentityURL+'/'+status+'/'+subEntityCode);
	}
	statusChangeSubentity(data:any): Observable<any> {
		return this.http.post<any>(this.statusChangeSubentityURL,data);
	}
	getAllCategory(): Observable<any> {
		return this.http.get<any>(this.getAllCategoryURL);
	}
	getDataCategoryDropdown(): Observable<any> {
		return this.http.get<any>(this.getDataCategoryDropdownURL);
	}
	addDataCategory(data: any): Observable<any> {
		return this.http.post(this.addDataCategoryURL, data);
	}
	editDataCategory(data: any): Observable<any> {
		return this.http.post(this.editDataCategoryURL, data);
	}
	getDataProviderStatusCategory(status:string,categoryId:number,entityCode:number): Observable<any> {
		return this.http.get<any>(this.getDataproviderCategoryURL+'/'+status+'/'+categoryId+'/'+entityCode);
	}
	getLocationAssignmentStatusCategory(status:string,categoryId:number,entityCode:number): Observable<any> {
		return this.http.get<any>(this.getLocationAssignmentCategoryURL+'/'+status+'/'+categoryId+'/'+entityCode);
	}
	statusChangeCategory(data:any): Observable<any> {
		return this.http.post<any>(this.statusChangeDataCategoryURL,data);
	}
	getReportLinks(emailAddress:string): Observable<any> {
		return this.http.get<any>(this.getReportsLinkURL+'/'+emailAddress);
	}
	getEconomicImpactList(): Observable<any> {
		return this.http.get<any>(this.getAllEconomicImpactDataURL);
	}
	createEconomicImpactData(data: any): Observable<any> {
		return this.http.post(this.createEconomicImpactDataURL, data);
	}
	updateEconomicImpactData(data: any): Observable<any> {
		return this.http.post(this.updateEconomicImpactDataURL, data);
	}

	saveAuditScreenLogData(userdata : any): Observable<any> {
		return this.http.post(this.saveAuditScreenLogDataURL, userdata);
	}

	async upload(files: any,dataCategoryId:number,entityId:number,STSToken) {
		return new Promise<any>(resolve => {
		var dynamicFolder;
		var dynamicBucket;
		if(dataCategoryId == 5) {
			dynamicFolder = environment.employeeDetailFolder+'/';
			dynamicBucket = environment.employeeDetailBucket;
		} else if(dataCategoryId == 6) {
			dynamicFolder = environment.investmentFolder+'/';
			dynamicBucket = environment.investmentBucket;
		} else if(dataCategoryId == 13) {
			dynamicFolder = environment.payrollFolder+'/';
			dynamicBucket = environment.payrollBucket;
		} else if(dataCategoryId == 8) {
			dynamicFolder = environment.dealerFolder+'/';
			dynamicBucket = environment.dealerBucket;
		}else {
			dynamicFolder = environment.employeeDetailFolder+'/';
			dynamicBucket = environment.employeeDetailBucket;
		}
		var fileObj;
		files.forEach(file => {
			fileObj = file;
		});
		var fileName = fileObj.name.replace(/[&\/\\#, +()$~%@'":*?<>{}]/g, '_');
		 const bucket = new S3(
			{			
				accessKeyId: STSToken.AwsRoleCredential.cred[0].AccessKeyId,
				secretAccessKey: STSToken.AwsRoleCredential.cred[0].SecretAccessKey,
        		sessionToken:STSToken.AwsRoleCredential.cred[0].SessionToken
			}
		);			 
		const params = {
			Bucket: dynamicBucket,
			Key: dynamicFolder + fileName,
			Body: fileObj,
			ServerSideEncryption: "AES256"
		};
		bucket.upload(params, function (err, data) {
			if (err) { 
				resolve(err);
			} else {
				resolve(data);
			}
		});
		}); 
	}	
	async uploadReport(files: any,sectionId:number,STSToken) {
		return new Promise<any>(resolve => {
		var reportFolder = environment.reportsFolder+'/';
		var reportBucket = environment.reportsBucket;		
		var fileObj;
		files.forEach(file => {
			fileObj = file;
		});
		var fileName = fileObj.name.replace(/[&\/\\#, +()$~%@'":*?<>{}]/g, '_');
		 const bucket = new S3(
			{
				accessKeyId: STSToken.AwsRoleCredential.cred[0].AccessKeyId,
				secretAccessKey: STSToken.AwsRoleCredential.cred[0].SecretAccessKey,
        		sessionToken:STSToken.AwsRoleCredential.cred[0].SessionToken
			}
		);			 
		const params = {
			Bucket: reportBucket,
			Key: reportFolder + fileName,
			Body: fileObj,
			ServerSideEncryption: "AES256"
		};
		bucket.upload(params, function (err, data) {
			if (err) { 
				resolve(err);
			} else {
				resolve(data);
			}
		});
		}); 
	}
	showSuccess(title='Success!',message='') {
		this.toastr.success(message,title);
	}
	showError(title='Failure!',message='') {
		this.toastr.error(message,title);
	}	
	getSTSToken(): Observable<any> {
		return this.http.get<any>(this.assumeRoleURL);
	}
	getAuthToken(): Observable<any> {
		return this.http.get<any>(this.getAuthTokenURL);
	}
	getS3FileURL(bucketName,fileName,foldername): Observable<any> {
		var data = {"bucketName":bucketName,"key":foldername+'/'+fileName};
		return this.http.post<any>(this.getS3Files,data);
	}

	async uploadLegislativeDataRefresh(files: any,STSToken) {
		return new Promise<any>(resolve => {
		var legislativeDataRefreshFolder = environment.legislativeDataRefreshFolder+'/';
		var legislativeDataRefreshBucket = environment.legislativeDataRefreshBucket;		
		var fileObj;
		files.forEach(file => {
			fileObj = file;
            var fileName = fileObj.name.replace(/[&\/\\#, +()$~%@'":*?<>{}]/g, '_');
            const bucket = new S3(
              {
                  accessKeyId: STSToken.AwsRoleCredential.cred[0].AccessKeyId,
                  secretAccessKey: STSToken.AwsRoleCredential.cred[0].SecretAccessKey,
                  sessionToken:STSToken.AwsRoleCredential.cred[0].SessionToken
              }
            );			 
            const params = {
                Bucket: legislativeDataRefreshBucket,
                Key: legislativeDataRefreshFolder + fileName,
                Body: fileObj,
                ServerSideEncryption: "AES256"
            };
            bucket.upload(params, function (err, data) {
                if (err) { 
                    resolve(err);
                } else {
                    resolve(data);
                }
            });
        });
		
	  }); 
	}

	addressValidationDev(address): Observable<any> {
		return this.http.get<any>(`https://personator.melissadata.net/v3/WEB/ContactVerify/doContactVerify?t=12345&id=U8DoN0InAAVsjC4-g6CZCa**&act=Check&cols=GrpCensus,GrpCensus&comp=Melissa%20Data&a1=${address.a1}&a2=${address.a2}&subadmarea=undefined&admarea=${address.state}&postal=${address.zip}&ctry=United%20States%20of%20America&format=JSON`);
	}
}
