import { Component, OnInit,Injectable,AfterViewInit,ViewEncapsulation,ViewChild} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute,Router } from '@angular/router'; 
import {ErrorStateMatcher} from '@angular/material/core';
import { FormControl,FormGroup, FormBuilder, Validators,FormGroupDirective, NgForm } from '@angular/forms';
import {MatDialog} from "@angular/material/dialog";
import {MatDialogConfig} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import { ApiService } from '../services/api.service';
import { DialogComponent } from '../dialog.component';
import { AdminDialogComponent } from '../adminDialog.component';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { entityDataService } from '../services/entityData.service';
@Component({
  selector: 'app-adminstration',
  templateUrl: './adminstration.component.html',
  styleUrls: ['./adminstration.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AdminstrationComponent implements OnInit,AfterViewInit {	

  admindisplayedColumns: string[] = ['action','firstname','lastname','emailaddress','phone','roleName','delete'];  
  adminColumns: any = [{'label':'Edit'},{'label':'First Name'},{'label':'Last Name'},{'label':'Email Address'},{'label':'Phone'},{'label':'Role'},{'label':'Remove'}];
  
  categoryDisplayedColumns: string[] = ['action','EntityCode','EntityShort','DataCategory','InputMethod','ReportingFrequency','Report_Production_Employee','Status','Status_Note_Txt','Activated_Dte','Deactivated_Dte','Created_Dte','Last_Updated_Dte'];
  categoryColumns: any = [{'label':'Action'},{'label':'Entity Code'},{'label':'Entity Name'},
  {'label':'Data Category'},{'label':'Input Method'},{'label':'Reporting Frequency'},{'label':'Report Prod Emp'},
  {'label':'Status'},{'label':'Status Notes'},{'label':'Activated'},{'label':'Deactivated'},{'label':'Created'},{'label':'Last Updated'}];
  
  entityDisplayedColumns: string[] = ['action','EntityShort','EntityLong','EntityType','Country','Status','Status_Note_Txt','Activated_Dte','Deactivated_Dte','Created_Dte','Last_Updated_Dte'];
  entityColumns: any = [{'label':'Action'},{'label':'Code'},{'label':'Name'},{'label':'Type'},{'label':'Country'},
  {'label':'Status'},{'label':'Status Notes'},{'label':'Activated'},{'label':'Deactivated'},{'label':'Created'},{'label':'Last Updated'}];
  
  subEntityDisplayedColumns: string[] = ['action','SubEntityShort','SubEntityLong','EntityShort','EntityLong','Databook_Affiliate','Diversity_Affiliate','Auto_Mfr_Plant','Status','Status_Note_Txt','Activated_Dte','Deactivated_Dte','Created_Dte','Last_Updated_Dte'];
  subEntityColumns: any = [{'label':'Action'},{'label':'Code'},{'label':'Name'},{'label':'Parent Code'},{'label':'Parent Name'},
  {'label':'Databook Affiliate EntityCode'},{'label':'diversity Affiliate EntityCode'},{'label':'Auto Mfg Plant'},
  {'label':'Status'},{'label':'Status Notes'},{'label':'Activated'},{'label':'Deactivated'},{'label':'Created'},{'label':'Last Updated'}];

  companydisplayedColumns: string[] = ['company','EntityLong','SubEntityLong','delete'];  
  companyColumns: any = [{'label':'Company Name'},{'label':'Entity'},{'label':'Sub Entity'},{'label':'Remove'}];

  sectiondisplayedColumns: string[] = ['edit','sectionName','delete'];  
  sectionColumns: any = [{'label':'Edit'},{'label':'Section Name'},{'label':'Remove'}];

  economicdisplayedColumns: string[] = ['edit','DETAIL','USCOUNT','NACOUNT','YEAR'];  
  economicColumns: any = [{'label':'Edit'},{'label':'Title'},{'label':'U.S. Count'},{'label':'North America Count'},{'label':'Year'}];

  adminDataSource = new MatTableDataSource();
  companyDataSource = new MatTableDataSource();
  sectionDataSource = new MatTableDataSource();
  entityDataSource = new MatTableDataSource();
  subEntityDataSource = new MatTableDataSource();
  categoryDataSource = new MatTableDataSource();
  economicDataSource = new MatTableDataSource();
  adminpageLength: number;
  adminpageSize: number = 20;
  admindataLength: number;
  adminList: any;
  companypageLength: number;
  companypageSize: number = 20;
  companydataLength: number;
  companyList: any;
  sectionpageLength: number;
  sectionpageSize: number = 20;
  sectiondataLength: number;
  sectionList: any=[];
  entitypageLength: number;
  entitypageSize: number = 20;
  entitydataLength: number;
  entityList: any;
  subEntitypageLength: number;
  subEntitypageSize: number = 20;
  subEntitydataLength: number;
  subEntityList: any;
  categorypageLength: number;
  categorypageSize: number = 20;
  categorydataLength: number;
  categoryList: any;
  economicpageLength: number;
  economicpageSize: number = 20;
  economicdataLength: number;
  economicList: any;
  encryptText: string;
  tabIndex = localStorage.getItem('adminTabIndex') ? Number(localStorage.getItem('adminTabIndex')) : 0;
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatSort) adminsort: MatSort;
  @ViewChild(MatSort) companysort: MatSort;
  @ViewChild(MatSort) sectionsort: MatSort;
  @ViewChild(MatSort) entitysort: MatSort;
  @ViewChild(MatSort) subEntitysort: MatSort;
  @ViewChild(MatSort) categorysort: MatSort;
  @ViewChild(MatSort) economicsort: MatSort;
  @ViewChild('adminpaginator') adminpaginator: MatPaginator;
  @ViewChild('companypaginator') companypaginator: MatPaginator;
  @ViewChild('sectionpaginator') sectionpaginator: MatPaginator;
  @ViewChild('entitypaginator') entitypaginator: MatPaginator;
  @ViewChild('subEntitypaginator') subEntitypaginator: MatPaginator;
  @ViewChild('categorypaginator') categorypaginator: MatPaginator;
  @ViewChild('economicpaginator') economicpaginator: MatPaginator;
  currentUser: any;
  matcher = new MyErrorStateMatcher();
  allRoles:any;
constructor(private ApiService: ApiService,private frmBuilder: FormBuilder,
  private dialog: MatDialog,private activeRoute: ActivatedRoute,
  private router: Router,private entityDataService:entityDataService) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
  }
  ngOnInit() {
    this.getAdmins();
    this.getCompany(); 
    this.getAllRoles();
    this.getSection();
    this.getAllEntity();
    this.getAllSubEntity();
    this.getAllEconomicImpact();
    this.getAllCategory();
  }
  ngAfterViewInit() {    
    this.adminDataSource.sort = this.adminsort;
    this.companyDataSource.sort = this.companysort;
    this.sectionDataSource.sort = this.sectionsort;
    this.entityDataSource.sort = this.entitysort;
    this.subEntityDataSource.sort = this.subEntitysort;
    this.categoryDataSource.sort = this.categorysort;
    this.economicDataSource.sort = this.economicsort;
  }
  onLinkClick(event: MatTabChangeEvent) {
    localStorage.setItem('adminTabIndex', String(event.index));
    setTimeout(() => {
      switch (event.index) {
        case 0:
          !this.adminDataSource.paginator ? this.adminDataSource.paginator = this.adminpaginator : null;
          break;
        case 2:
          !this.entityDataSource.paginator ? this.entityDataSource.paginator = this.entitypaginator : null;
        case 4:        
          !this.companyDataSource.paginator ? this.companyDataSource.paginator = this.companypaginator : null;          
      }
    });
  }
  applyFilter(filterValue: string,type: string) {
    if(type == 'admin') {
      this.adminDataSource.filter = filterValue.trim().toLowerCase();
      if (this.adminDataSource.paginator) {
        this.adminDataSource.paginator.firstPage();
      }
    }
    if(type == 'entity') {
      this.entityDataSource.filter = filterValue.trim().toLowerCase();
      if (this.entityDataSource.paginator) {
        this.entityDataSource.paginator.firstPage();
      }
    }
    if(type == 'subEntity') {
      this.subEntityDataSource.filter = filterValue.trim().toLowerCase();
      if (this.subEntityDataSource.paginator) {
        this.subEntityDataSource.paginator.firstPage();
      }
    }
    if(type == 'category') {
      this.categoryDataSource.filter = filterValue.trim().toLowerCase();
      if (this.categoryDataSource.paginator) {
        this.categoryDataSource.paginator.firstPage();
      }
    }
    if(type == 'company') {
      this.companyDataSource.filter = filterValue.trim().toLowerCase();
      if (this.companyDataSource.paginator) {
        this.companyDataSource.paginator.firstPage();
      }
    }     
    if(type == 'section') {
      this.sectionDataSource.filter = filterValue.trim().toLowerCase();
      if (this.sectionDataSource.paginator) {
        this.sectionDataSource.paginator.firstPage();
      }
    }
    if(type == 'economic') {
      this.economicDataSource.filter = filterValue.trim().toLowerCase();
      if (this.economicDataSource.paginator) {
        this.economicDataSource.paginator.firstPage();
      }
    }
    
  }
  gotoContent(pageId) {
    this.router.navigate(['databook/manageContents',pageId,1022]);
  }
  addCategory() {
    this.router.navigate(['databook/manageCategory',0,1019]);
  }
  editCategory(element){
    this.entityDataService.categoryObj = element;
    this.router.navigate(['databook/manageCategory',element.DataCategoryKey,1019]); 
  }
  addEntity() {    
    this.router.navigate(['databook/manageEntity',0,1020]);
  }
  editEntity(element) {
    this.entityDataService.entityObj = element;
    this.router.navigate(['databook/manageEntity',element.EntityCode,1020]);
  }
  addSubEntity() {
    this.router.navigate(['databook/manageSubEntity',0,1021]);
  }
  editSubEntity(element) {
    this.entityDataService.subEntityObj = element;
    this.router.navigate(['databook/manageSubEntity',element.SubEntityCode,1021]);
  }
  getAdmins() {
    this.ApiService.getAdminsList().subscribe(
      data => {
        this.adminList = data.Admins;
        this.adminDataSource.data = data.Admins;					
        this.admindataLength = data.Admins.length;
        this.adminpageLength = Math.ceil(this.admindataLength / this.adminpageSize);
        setTimeout(() => this.adminDataSource.paginator = this.adminpaginator);       
      },
			error => {
     });
  }
  getAllRoles() {
    this.ApiService.getAllRoles().subscribe(
      data => {
        this.allRoles = data.roles;
      },
			error => {
     });
  }
  getCompany() {
    this.ApiService.getCompanyList().subscribe(
      data => {
        this.companyList = data.company;
        this.companyDataSource.data = data.company;					
        this.companydataLength = data.company.length;
        this.companypageLength = Math.ceil(this.companydataLength / this.companypageSize);
        setTimeout(() => this.companyDataSource.paginator = this.companypaginator);   
      },
			error => {
     });
  } 
  getSection() {
    this.sectionList=[]
    this.ApiService.getSectionList().subscribe(
      data => {
        data.sections.forEach(element => {
          if(element.sectionName!=='Monthly Reports'){
            this.sectionList.push(element)
          }
        });
        this.sectionDataSource.data = this.sectionList;					
        this.sectiondataLength = this.sectionList.length;
        this.sectionpageLength = Math.ceil(this.sectiondataLength / this.sectionpageSize);
        setTimeout(() => this.sectionDataSource.paginator = this.sectionpaginator);   
      },
			error => {
     });
  }
  getAllEconomicImpact() {
    this.ApiService.getEconomicImpactList().subscribe(
      data => {
        this.economicList = data.economicImpactData;
        this.economicDataSource.data = data.economicImpactData;					
        this.economicdataLength = data.economicImpactData.length;
        this.economicpageLength = Math.ceil(this.economicdataLength / this.economicpageSize);
        setTimeout(() => this.economicDataSource.paginator = this.economicpaginator);       
      },
			error => {
     });
  }
  getAllEntity(){
    this.ApiService.getAllEntity().subscribe(
      data => {
        this.entityList = data.entity;
        this.entityDataSource.data = data.entity;					
        this.entitydataLength = data.entity.length;
        this.entitypageLength = Math.ceil(this.entitydataLength / this.entitypageSize);
        setTimeout(() => this.entityDataSource.paginator = this.entitypaginator);   
      },
			error => {

     });
  }
  getAllSubEntity(){
    this.ApiService.getAllSubEntity().subscribe(
      data => {
        this.subEntityList = data.Subentity;
        this.subEntityDataSource.data = data.Subentity;					
        this.subEntitydataLength = data.Subentity.length;
        this.subEntitypageLength = Math.ceil(this.subEntitydataLength / this.subEntitypageSize);
        setTimeout(() => this.subEntityDataSource.paginator = this.subEntitypaginator);   
      },
			error => {

     });
  }
  getAllCategory(){
    this.ApiService.getAllCategory().subscribe(
      data => {
        this.categoryList = data.DataCategory;
        this.categoryDataSource.data = data.DataCategory;					
        this.categorydataLength = data.DataCategory.length;
        this.categorypageLength = Math.ceil(this.categorydataLength / this.categorypageSize);
        setTimeout(() => this.categoryDataSource.paginator = this.categorypaginator);   
      },
			error => {

     });
  }
  openAddAdminDialog() {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "ADD ADMIN",
			title: 'ADD ADMIN',
      page:'add-admin',
      rolesDeatail:this.allRoles
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getAdmins();
				}				
		}); 
  }
  editAdminDialog(element) {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "EDIT ADMIN",
			title: 'EDIT ADMIN',
      page:'edit-admin',
      obj:element,
      rolesDeatail:this.allRoles
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getAdmins();
				}
				
		});	
  }
  removeAdminDialog(id) {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "REMOVE ADMIN",
			title: 'REMOVE ADMIN',
      page:'remove-admin',
      adminId:id
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getAdmins();
				}				
		}); 
  }
  openAddCompanyDialog() {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "ADD COMPANY",
			title: 'ADD COMPANY',
			page:'add-company'
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getCompany();
				}				
		}); 
  }
  removeCompanyDialog(id) {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "REMOVE COMPANY",
			title: 'REMOVE COMPANY',
      page:'remove-company',
      companyId:id
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getCompany();
				}				
		}); 
  }
  addSectionDialog() {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "ADD SECTION",
			title: 'ADD SECTION',
			page:'add-section'
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getSection();
				}
				
		});	
  }
  editSectionDialog(element) {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "EDIT SECTION",
			title: 'EDIT SECTION',
      page:'edit-section',
      obj:element
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getSection();
				}
				
		});	
  }
  removeSectionDialog(id) {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "REMOVE SECTION",
			title: 'REMOVE SECTION',
      page:'remove-section',
      sectionId:id
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getSection();
				}
				
		});	
  }
  openAddEconomicDialog() {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "ADD ECONOMIC IMPACT DATA",
			title: 'ADD ECONOMIC IMPACT DATA',
      page:'add-economic'
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getAllEconomicImpact();
				}				
		}); 
  }
  editEconomicDialog(element) {
    const dialogConfig = new MatDialogConfig();
		dialogConfig.disableClose = true;
		dialogConfig.autoFocus = true;
		dialogConfig.data = {
			message: "EDIT ECONOMIC IMPACT DATA",
			title: 'EDIT ECONOMIC IMPACT DATA',
      page:'edit-economic',
      obj:element
		};
		const dialogRef = this.dialog.open(DialogComponent, {data:{dialogConfig,component:AdminDialogComponent},width:'850px'});
		dialogRef.afterClosed().subscribe(
			data =>{
				if(data){
          this.getAllEconomicImpact();
				}				
		}); 
  }
}

/** Error when invalid control is dirty, touched, or submitted.*/
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted; 
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}